// @ts-nocheck
import React from "react";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./FAQ.css";
import FAQS from './faqs.json'
function FAQ() {
	const [Faqs, setFaqs] = useState(FAQS.slice(0, 3))
	return (
		<div id='faq' className=' faq'>
			<div className='title'>FAQ</div>
			<div className='questions px-3'>
				<Accordion flush>
					{Faqs.map((faq, id) => {
						return <Accordion.Item eventKey={id}>
							<Accordion.Header>
								<div className='pe-2'>
									{faq.question}
								</div>
							</Accordion.Header>
							<Accordion.Body>
								{
									faq.answer
								}
							</Accordion.Body>
						</Accordion.Item>
					})}

				</Accordion>
			</div>
			<div className='d-flex align-items-center justify-content-center px-3'>
				<div className='viewAllBtn' onClick={() => {
					Faqs.length <= 3 ? setFaqs(FAQS) :setFaqs(FAQS.slice(0,3)) 

				}}>{Faqs.length <= 3 ? "View All" : "View Less"}</div>
			</div>
		</div>
	);
}


export default FAQ;
