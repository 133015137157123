import React, { useState } from "react";
import "./Nav.css";
import { useAnimation } from "framer-motion";
import HemburgerMenu from "./HemburgerMenu/HemburgerMenu";
import { Link } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
export default function Nav({ modalHandler }) {
	const hemburgerMenuBar = useAnimation();
	const navSlideropen = () => {
		const body = document.querySelector("body");
		body.classList.add("stopScroll");
		hemburgerMenuBar.start({
			x: 0,
		});
	};
	const handleWhatsapp = () => {
		window.open("https://wa.me/971585386009?text=Hi%20", "_blank");
	};

	const analytics = getAnalytics()

	const handleModal = () => {
		modalHandler(true)
		logEvent(analytics, 'Clicked On Join Today Red1')
	}

	return (
		<>
			<HemburgerMenu modalHandler={modalHandler} animation={hemburgerMenuBar} />
			<div className='nav d-flex align-items-center justify-content-between px-md-2 px-lg-5 px-3 py-3 py-sm-0'>
				<Link to='/'>
				<div className='navBrand  '>
					<img
						src='assets/AY_logo.svg'
						alt=''
						className='img-fluid webLogo'
					/>
					<img
						src='assets/Ay-Mobile.svg'
						alt=''
						className='img-fluid mblLogo'
					/>
				</div>
				</Link>
				

				<div className='d-flex align-items-center'>

					<div className='contact me-3 d-none d-md-block' >
						<div
							onClick={handleWhatsapp}
							className='d-flex align-items-center '>
							<span className='whatsapp me-2'>
								<img
									width="35px"
									height="32px"
									src='assets/WhatsappGreen.svg'
									alt=''
									className='img-fluid ms-1'
								/>
							</span>
							<span className="ChatWithUs">
								Chat with us
							</span>

						</div>

					</div>
					{/* <div className='contact me-3 d-none d-md-block' >
						<div
							onClick={handleWhatsapp}
							className='d-flex align-items-center '>
							<span className='phone'>
								<img
									src='assets/call.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='mx-2 text'>or</span>
							<span className='whatsapp me-2'>
								<img
									src='assets/whatsapp.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='number text'>+971585386009</span>
						</div>

					</div> */}
					<div
						className='joinToday'
						style={{ cursor: "pointer" }}
						onClick={() => handleModal()}>
						Join Today
					</div>
					<div
						style={{ cursor: "pointer" }}
						className='d-block d-sm-none ms-3'
						onClick={navSlideropen}>
						<img
							src='assets/hamburger.svg'
							alt=''
							className='img-fluid'
						/>
					</div>
				</div>
			</div>
		</>
	);
}
