import React, { useEffect, useState } from "react";
import ClientReviewMobile from "./ClientReviewMobile/ClientReviewMobile";
import "./ClientsReview.css";
import ReactPlayer from "react-player/lazy";
import axios from "axios";
import ReactPlayerComp from "../ReactPlayerComp/ReactPlayerComp";
import { v4 as uuidv4 } from "uuid";

export default function ClientsReview() {
	const [testimonial, setTestimonials] = useState([]);
	useEffect(() => {
		axios
			.get("testimonial.json")
			.then((res) => setTestimonials(res.data))
			.catch((err) => console.log(err));
	}, []);
	return (
		<div id='testimonials' className='review '>
			<h1 className='reviewHeader d-flex align-items-center justify-content-center '>
			What our clients have to say about us…
			</h1>
			<div className='reviewsVideo d-none d-sm-flex '>
				{testimonial.map((item, ind) => {
					return (
						<div className='col-12 col-sm-4 p-2'>
							<ReactPlayerComp
								url={item.url}
								thumb={item.thumb}
								uid={uuidv4()}
							/>
						</div>
					);
				})}
			</div>
			<div className='d-sm-none d-block'>
				<ClientReviewMobile />
			</div>
		</div>
	);
}
