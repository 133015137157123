// @ts-nocheck
import React, { useState, useEffect } from 'react';
import './TimeSchedule.css'
import city from "countries-and-timezones";
import Select from "react-select";
import useGeoLocation from "react-ipgeolocation";

const TimeSchedule = () => {
    const location = useGeoLocation();
    const countryTimezone = [
        { value: "AD", label: "Andorra" },
        { value: "AE", label: "United Arab Emirates" },
        { value: "AF", label: "Afghanistan" },
        { value: "AG", label: "Antigua and Barbuda" },
        { value: "AI", label: "Anguilla" },
        { value: "AL", label: "Albania" },
        { value: "AM", label: "Armenia" },
        { value: "AO", label: "Angola" },
        { value: "AQ", label: "Antarctica" },
        { value: "AR", label: "Argentina" },
        { value: "AS", label: "American Samoa" },
        { value: "AT", label: "Austria" },
        { value: "AU", label: "Australia" },
        { value: "AW", label: "Aruba" },
        { value: "AX", label: "Åland Islands" },
        { value: "AZ", label: "Azerbaijan" },
        { value: "BA", label: "Bosnia and Herzegovina" },
        { value: "BB", label: "Barbados" },
        { value: "BD", label: "Bangladesh" },
        { value: "BE", label: "Belgium" },
        { value: "BF", label: "Burkina Faso" },
        { value: "BG", label: "Bulgaria" },
        { value: "BH", label: "Bahrain" },
        { value: "BI", label: "Burundi" },
        { value: "BJ", label: "Benin" },
        { value: "BL", label: "Saint Barthélemy" },
        { value: "BM", label: "Bermuda" },
        { value: "BN", label: "Brunei" },
        { value: "BO", label: "Bolivia" },
        { value: "BQ", label: "Caribbean Netherlands" },
        { value: "BR", label: "Brazil" },
        { value: "BS", label: "Bahamas" },
        { value: "BT", label: "Bhutan" },
        { value: "BV", label: "Bouvet Island" },
        { value: "BW", label: "Botswana" },
        { value: "BY", label: "Belarus" },
        { value: "BZ", label: "Belize" },
        { value: "CA", label: "Canada" },
        { value: "CC", label: "Cocos Islands" },
        { value: "CD", label: "Democratic Republic of the Congo" },
        { value: "CF", label: "Central African Republic" },
        { value: "CG", label: "Republic of the Congo" },
        { value: "CH", label: "Switzerland" },
        { value: "CI", label: "Ivory Coast" },
        { value: "CK", label: "Cook Islands" },
        { value: "CL", label: "Chile" },
        { value: "CM", label: "Cameroon" },
        { value: "CN", label: "China" },
        { value: "CO", label: "Colombia" },
        { value: "CR", label: "Costa Rica" },
        { value: "CU", label: "Cuba" },
        { value: "CV", label: "Cabo Verde" },
        { value: "CW", label: "Curaçao" },
        { value: "CX", label: "Christmas Island" },
        { value: "CY", label: "Cyprus" },
        { value: "CZ", label: "Czechia" },
        { value: "DE", label: "Germany" },
        { value: "DJ", label: "Djibouti" },
        { value: "DK", label: "Denmark" },
        { value: "DM", label: "Dominica" },
        { value: "DO", label: "Dominican Republic" },
        { value: "DZ", label: "Algeria" },
        { value: "EC", label: "Ecuador" },
        { value: "EE", label: "Estonia" },
        { value: "EG", label: "Egypt" },
        { value: "EH", label: "Western Sahara" },
        { value: "ER", label: "Eritrea" },
        { value: "ES", label: "Spain" },
        { value: "ET", label: "Ethiopia" },
        { value: "FI", label: "Finland" },
        { value: "FJ", label: "Fiji" },
        { value: "FK", label: "Falkland Islands" },
        { value: "FM", label: "Micronesia" },
        { value: "FO", label: "Faroe Islands" },
        { value: "FR", label: "France" },
        { value: "GA", label: "Gabon" },
        { value: "GB", label: "United Kingdom" },
        { value: "GD", label: "Grenada" },
        { value: "GE", label: "Georgia" },
        { value: "GF", label: "French Guiana" },
        { value: "GG", label: "Guernsey" },
        { value: "GH", label: "Ghana" },
        { value: "GI", label: "Gibraltar" },
        { value: "GL", label: "Greenland" },
        { value: "GM", label: "Gambia" },
        { value: "GN", label: "Guinea" },
        { value: "GP", label: "Guadeloupe" },
        { value: "GQ", label: "Equatorial Guinea" },
        { value: "GR", label: "Greece" },
        { value: "GS", label: "South Georgia and the South Sandwich Islands" },
        { value: "GT", label: "Guatemala" },
        { value: "GU", label: "Guam" },
        { value: "GW", label: "Guinea-Bissau" },
        { value: "GY", label: "Guyana" },
        { value: "HK", label: "Hong Kong" },
        { value: "HM", label: "Heard Island and McDonald Islands" },
        { value: "HN", label: "Honduras" },
        { value: "HR", label: "Croatia" },
        { value: "HT", label: "Haiti" },
        { value: "HU", label: "Hungary" },
        { value: "value", label: "Indonesia" },
        { value: "IE", label: "Ireland" },
        { value: "IL", label: "Israel" },
        { value: "IM", label: "Isle of Man" },
        { value: "IN", label: "India" },
        { value: "IO", label: "British Indian Ocean Territory" },
        { value: "IQ", label: "Iraq" },
        { value: "IR", label: "Iran" },
        { value: "IS", label: "Iceland" },
        { value: "IT", label: "Italy" },
        { value: "JE", label: "Jersey" },
        { value: "JM", label: "Jamaica" },
        { value: "JO", label: "Jordan" },
        { value: "JP", label: "Japan" },
        { value: "KE", label: "Kenya" },
        { value: "KG", label: "Kyrgyzstan" },
        { value: "KH", label: "Cambodia" },
        { value: "KI", label: "Kiribati" },
        { value: "KM", label: "Comoros" },
        { value: "KN", label: "Saint Kitts and Nevis" },
        { value: "KP", label: "North Korea" },
        { value: "KR", label: "South Korea" },
        { value: "KW", label: "Kuwait" },
        { value: "KY", label: "Cayman Islands" },
        { value: "KZ", label: "Kazakhstan" },
        { value: "LA", label: "Laos" },
        { value: "LB", label: "Lebanon" },
        { value: "LC", label: "Saint Lucia" },
        { value: "LI", label: "Liechtenstein" },
        { value: "LK", label: "Sri Lanka" },
        { value: "LR", label: "Liberia" },
        { value: "LS", label: "Lesotho" },
        { value: "LT", label: "Lithuania" },
        { value: "LU", label: "Luxembourg" },
        { value: "LV", label: "Latvia" },
        { value: "LY", label: "Libya" },
        { value: "MA", label: "Morocco" },
        { value: "MC", label: "Monaco" },
        { value: "MD", label: "Moldova" },
        { value: "ME", label: "Montenegro" },
        { value: "MF", label: "Saint Martin" },
        { value: "MG", label: "Madagascar" },
        { value: "MH", label: "Marshall Islands" },
        { value: "MK", label: "North Macedonia" },
        { value: "ML", label: "Mali" },
        { value: "MM", label: "Myanmar" },
        { value: "MN", label: "Mongolia" },
        { value: "MO", label: "Macao" },
        { value: "MP", label: "Northern Mariana Islands" },
        { value: "MQ", label: "Martinique" },
        { value: "MR", label: "Mauritania" },
        { value: "MS", label: "Montserrat" },
        { value: "MT", label: "Malta" },
        { value: "MU", label: "Mauritius" },
        { value: "MV", label: "Maldives" },
        { value: "MW", label: "Malawi" },
        { value: "MX", label: "Mexico" },
        { value: "MY", label: "Malaysia" },
        { value: "MZ", label: "Mozambique" },
        { value: "NA", label: "Namibia" },
        { value: "NC", label: "New Caledonia" },
        { value: "NE", label: "Niger" },
        { value: "NF", label: "Norfolk Island" },
        { value: "NG", label: "Nigeria" },
        { value: "NI", label: "Nicaragua" },
        { value: "NL", label: "Netherlands" },
        { value: "NO", label: "Norway" },
        { value: "NP", label: "Nepal" },
        { value: "NR", label: "Nauru" },
        { value: "NU", label: "Niue" },
        { value: "NZ", label: "New Zealand" },
        { value: "OM", label: "Oman" },
        { value: "PA", label: "Panama" },
        { value: "PE", label: "Peru" },
        { value: "PF", label: "French Polynesia" },
        { value: "PG", label: "Papua New Guinea" },
        { value: "PH", label: "Philippines" },
        { value: "PK", label: "Pakistan" },
        { value: "PL", label: "Poland" },
        { value: "PM", label: "Saint Pierre and Miquelon" },
        { value: "PN", label: "Pitcairn" },
        { value: "PR", label: "Puerto Rico" },
        { value: "PS", label: "Palestine" },
        { value: "PT", label: "Portugal" },
        { value: "PW", label: "Palau" },
        { value: "PY", label: "Paraguay" },
        { value: "QA", label: "Qatar" },
        { value: "RE", label: "Réunion" },
        { value: "RO", label: "Romania" },
        { value: "RS", label: "Serbia" },
        { value: "RU", label: "Russia" },
        { value: "RW", label: "Rwanda" },
        { value: "SA", label: "Saudi Arabia" },
        { value: "SB", label: "Solomon Islands" },
        { value: "SC", label: "Seychelles" },
        { value: "SD", label: "Sudan" },
        { value: "SE", label: "Sweden" },
        { value: "SG", label: "Singapore" },
        { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
        { value: "SI", label: "Slovenia" },
        { value: "SJ", label: "Svalbard and Jan Mayen" },
        { value: "SK", label: "Slovakia" },
        { value: "SL", label: "Sierra Leone" },
        { value: "SM", label: "San Marino" },
        { value: "SN", label: "Senegal" },
        { value: "SO", label: "Somalia" },
        { value: "SR", label: "Surilabel" },
        { value: "SS", label: "South Sudan" },
        { value: "ST", label: "Sao Tome and Principe" },
        { value: "SV", label: "El Salvador" },
        { value: "SX", label: "Sint Maarten" },
        { value: "SY", label: "Syria" },
        { value: "SZ", label: "Eswatini" },
        { value: "TC", label: "Turks and Caicos Islands" },
        { value: "TD", label: "Chad" },
        { value: "TF", label: "French Southern Territories" },
        { value: "TG", label: "Togo" },
        { value: "TH", label: "Thailand" },
        { value: "TJ", label: "Tajikistan" },
        { value: "TK", label: "Tokelau" },
        { value: "TL", label: "Timor-Leste" },
        { value: "TM", label: "Turkmenistan" },
        { value: "TN", label: "Tunisia" },
        { value: "TO", label: "Tonga" },
        { value: "TR", label: "Turkey" },
        { value: "TT", label: "Trin value ad and Tobago" },
        { value: "TV", label: "Tuvalu" },
        { value: "TW", label: "Taiwan" },
        { value: "TZ", label: "Tanzania" },
        { value: "UA", label: "Ukraine" },
        { value: "UG", label: "Uganda" },
        { value: "UM", label: "United States Minor Outlying Islands" },
        { value: "US", label: "United States of America" },
        { value: "UY", label: "Uruguay" },
        { value: "UZ", label: "Uzbekistan" },
        { value: "VA", label: "Holy See" },
        { value: "VC", label: "Saint Vincent and the Grenadines" },
        { value: "VE", label: "Venezuela" },
        { value: "VG", label: "Virgin Islands (UK)" },
        { value: "VI", label: "Virgin Islands (US)" },
        { value: "VN", label: "Vietnam" },
        { value: "VU", label: "Vanuatu" },
        { value: "WF", label: "Wallis and Futuna" },
        { value: "WS", label: "Samoa" },
        { value: "YE", label: "Yemen" },
        { value: "YT", label: "Mayotte" },
        { value: "ZA", label: "South Africa" },
        { value: "ZM", label: "Zambia" },
        { value: "ZW", label: "Zimbabwe" },
    ];
    const [country, setCountry] = useState({
        value: "AE",
        label: "United Arab Emirates",
    });
    const eventHandle = (e) => {
        setCountry(e);
    };
    useEffect(() => {
        // setCountryLocation(location.country);
        // console.log(location.isLoading, location.country);
        let c = countryTimezone.filter((item) => item.value === location.country);
        // console.log(c[0]);
        // setCountry(c[0]);
        !location.isLoading && setCountry(c[0]);
    }, [location.isLoading]);
    const MorningSchedules = [
        { time: "5:30 AM" },
        { time: "6:00 AM" },
        { time: "6:30 AM" },
        { time: "7:15 AM" },
        { time: "8:15 AM" ,
    ladiesOnly:true
    },
        {
            time: "9:00 AM",
            ladiesOnly: true
        },
        {
            time: "10:30 AM",
            ladiesOnly: true
        },

]
    const EveningSchedules = [
        {
            time: "6:00 PM",
            ladiesOnly: true
        },
        { time: "6:05 PM" },
        { time: '7:15 PM' },
        { time: "8:30 PM" },
]
    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
            borderBottom: "1px dotted pink",
            background: "white",
            color: "red",
            //   padding: 20,
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "red" : "black",
            //   background: state.isFocused ? "#ff7a7a " : "white",
            backgroundColor: state.isSelected ? "white" : "white",
        }),
    };


    const convertTimeToMinutesFromMidNight = (timeInString) => {
        var spliTimes = timeInString.split(" ");
        var addToMinutes = 0;
        var horsMinsArray = spliTimes[0].split(":");
        var timeinhrs = horsMinsArray[0];
        var timeinMins = horsMinsArray[1];
        if (spliTimes[spliTimes.length - 1] == "AM") {
            timeinhrs = timeinhrs != 12 ? timeinhrs : 0;
        } else {
            addToMinutes = 720;
        }

        var totalTimeInMins = timeinhrs * 60 + parseInt(timeinMins);

        var timeInMinsFromMidnight = totalTimeInMins + addToMinutes;
        return timeInMinsFromMidnight;
    };

    const convertMinutesFromMidNightToTime = (timeInmIns, timeInString) => {
        var isAM = true;
        if (timeInmIns > 720 || timeInmIns < 0) {
            isAM = false;
            timeInmIns = timeInmIns > 0 ? timeInmIns - 720 : 1440 + timeInmIns;
            timeInmIns = timeInmIns > 720 ? timeInmIns - 720 : timeInmIns;
        }

        var timinhrs = timeInmIns / 60;
        timinhrs = Math.floor(timinhrs);
        var timeInMinutes = timeInmIns - timinhrs * 60;
        timeInMinutes = timeInMinutes < 10 ? "0" + timeInMinutes : timeInMinutes;
        var timeInString = timinhrs + ":" + timeInMinutes + " AM";
        if (!isAM) {
            timeInString = timinhrs + ":" + timeInMinutes + " PM";
        }

        return timeInString;
    };
    const showNewTime = (time) => {
        if (country.value === "AE") {
            return time;
        }
        const currentTimeZone = city.getTimezonesForCountry("AE");
        // console.log(country);
        const newTimeZone = city.getTimezonesForCountry(country.value);
        // @ts-ignore
        const timeDiff = newTimeZone[0].utcOffset - currentTimeZone[0].utcOffset;
        var timeInMinsFromMidnight = convertTimeToMinutesFromMidNight(time);
        var displayTime = timeInMinsFromMidnight + timeDiff;

        displayTime = convertMinutesFromMidNightToTime(displayTime, time);
        return displayTime;
    };
    const timezoneOffset = (new Date()).getTimezoneOffset();

    // console.log(timezoneOffset);

    var d = new Date();
    return (
        <div className=' px-3 mt-5'>
            <div className='Advertisement g-2 row py-3'>
                <div className=' yogaGirl  col-sm-12 col-md-2 '>
                </div>
                <div className=' col-sm-12 col-md-10 px-3 MainTittleSection'>
                    <div className=' tittleSection'>
                        <div className='d-block  d-md-flex d-sm-block justify-content-between '>
                            <div >
                                <h3 className='TimesScheduleTittle'>
                                Batch Timings                                </h3>
                            </div>
                            <div className='d-none d-md-flex d-sm-none align-items-center timezone'>
                                {/* <select className="form-select" >
                                <option selected>Select Your Timezone</option>
                            </select> */}
                                <Select

                                    styles={customStyles}
                                    options={countryTimezone}
                                    value={country}
                                    onChange={eventHandle}
                                    className='timezone-select ms-lg-auto'
                                    placeholder='Select your timezone'
                                />
                            </div>
                        </div>
                        <div className='TimesHeader  '>
                            <div className='d-flex align-items-center '>
                                <img src='assets/VedioSmall.svg' alt='' />
                                <p>
                                    Live Zoom Class                                    </p>
                            </div>
                            <div className='d-flex align-items-center '>
                                <img src='assets/calenderSmall.svg' alt='' />
                                <p>
                                    5 Days a Week (Mon-Fri)
                                </p>
                            </div>
                            <div className='d-flex align-items-center '>
                                <img src='assets/TimerSmall.svg' alt='' />
                                <p>
                                    1 Hour Daily
                                </p>
                            </div>
                        </div>
                        <div className='d-block d-md-none d-sm-block align-items-center timezone'>
                                {/* <select className="form-select" >
                                <option selected>Select Your Timezone</option>
                            </select> */}
                                <Select

                                    styles={customStyles}
                                    options={countryTimezone}
                                    value={country}
                                    onChange={eventHandle}
                                    className='timezone-select ms-lg-auto'
                                    placeholder='Select your timezone'
                                />
                            </div>


                    </div>
                    <hr className='mb-4 mt-4 horizontalLine' />
                    <div className='schedules'>
                        <h5>AM (Morning)</h5>
                        <p>Duration - 1 Hour</p>
                        <div className='timeSection'>
                            {MorningSchedules.map((schedule) => {
                                return < span className={` ${schedule?.ladiesOnly ? "rounded-pill badge timing-badge ladiesTimes" : "rounded-pill badge timing-badge "}`}>
                                {
                                    showNewTime(schedule.time)
                                }
                            </span>
                            })
                            }
                        </div>
                    </div>
                    <div className='schedules mt-3'>
                        <h5>PM (Evening)</h5>
                        <p>Duration - 1 Hour</p>
                        <div className='timeSection'>
                            {EveningSchedules.map((schedule) => {
                                return < span className={` ${schedule?.ladiesOnly ? "rounded-pill badge timing-badge ladiesTimes" : "rounded-pill badge timing-badge "}`}>
                                        {
                                            showNewTime(schedule.time)
                                        }
                                    </span>

                            })
                            }
                        </div>
                    </div>
                    <div className='ladies-only'>
                    <span>
                      <i className='fas fa-square' />
                      Ladies only
                      <p />
                    </span>
                  </div>
                </div>
            </div>
        </div >
    );
};

export default TimeSchedule;