import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Congratulations from "./Congratulations/Congratulations";
import "./JoinNowModal.css";

export default function JoinNowModal(props) {
	// const [downloadDone, setDownloadDone] = useState(true);
	return (
		<Modal
			{...props}
			// size='md'
			backdrop='static'
			keyboard={false}
			dialogClassName='modal-60w joinNow'
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<Modal.Body>
				<Congratulations {...props} />
			</Modal.Body>
		</Modal>
	);
}
