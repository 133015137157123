// @ts-nocheck
import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import JoinAdd from "../JoinAdd/JoinAdd";
import "./Advertisement.css";
import moment from "moment/moment";
import axios from "axios";
export default function Advertisement({ type, modalHandler }) {

	const [offerDate,setOfferDate] = useState(null)


	function diffMinutes(date1, date2) {
		const d1 = new Date(date1).getTime();
		const d2 = new Date(date2).getTime();
		return Math.abs(Math.round(d2 - d1)); // Can use Math.floor or Math.ceil depends up to you
	}
	const diff = () => {
		const current = new Date();
		let future;

		const currentDate = moment()
		const isoFormat = moment(offerDate * 1000).format()
		console.log("ISo",moment(offerDate * 1000).format())
		console.log(moment().endOf('month').format())

		if(offerDate && moment(isoFormat).isAfter(currentDate)){
			future = moment(offerDate * 1000).format()
		}
		else if(offerDate && moment(isoFormat).isBefore(currentDate)){
			future = moment().endOf('month').format()
		}
		
		else if(moment(offerDate * 1000).format() == moment().endOf('month').format() ){
			future = moment().endOf('month').format()
		}
		else{
			future = moment().endOf('month').format()
		}
		// else{
		// 	future = moment().endOf('month').format()
		// }

		// let future = new Date(moment().endOf('month').format());
		// const future = new Date(process.env.REACT_APP_FUTURE_DATE);
		return diffMinutes(future, current);
	};


	useEffect(() => {
	  
		axios.get("https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/offerDates")
		.then((res) => {
			console.log("Offer Dates",res)
			const red1Date = res.data.filter((item) => {
				return item?.docId == "red1"
			})
			console.log(red1Date)
			setOfferDate(red1Date[0]?.offerDate?._seconds)
			// setOfferDate(res.data[0])
		})
		.catch((err) => {
			console.log(err)
			
		})

	}, [])




	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		return (
			<div className='d-flex align-items-center justify-content-sm-evenly justify-content-between'>
				<div className='' style={{ width: "55px" }}>
					<div className='timeCountdown'>
						<span className='me-2'>{zeroPad(days)}</span>{" "}
						<span>:</span>
					</div>

					<div className='TimeDatatype'>Days</div>
				</div>
				<div className='' style={{ width: "55px" }}>
					<div className='timeCountdown'>
						<span className='me-2'>{zeroPad(hours)}</span>{" "}
						<span>:</span>
					</div>
					<div className='TimeDatatype'>Hours</div>
				</div>
				<div className='' style={{ width: "55px" }}>
					<div className='timeCountdown'>
						<span className='me-2'>{zeroPad(minutes)}</span>{" "}
						<span>:</span>
					</div>

					<div className='TimeDatatype'>Minutes</div>
				</div>
				<div style={{ width: "55px" }}>
					<div className='timeCountdown'>
						<span className=''>{zeroPad(seconds)}</span>{" "}
					</div>

					<div className='TimeDatatype'>Seconds</div>
				</div>
			</div>
		);
	};
	const ad1 = () => {
		return (
			<>
				<div>
					<h2 className='title'>
						No Two Classes are the{" "}
						<span className='pink'>SAME!</span>
					</h2>
					<p className='brief my-3'>
						Every Session Surprises The Body, Activates New Muscle
						And is Uniquely Designed to Give Maximum Results.
					</p>
				</div>
				<div className='col-8 d-none d-sm-block mt-3 mb-2 '>
					<div className="OfferNeverBefore joinAd flex-column">
						<div >
							Never Before Offer
						</div>
					</div>
				</div>
				<div className='col-8 d-none d-sm-block '>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};
	const ad2 = () => {
		return (
			<>
				<div>
					<h2 className='title'>
						The majority of people gain{" "}
						<br className='d-none d-sm-block' /> back the weight
						they lose, <br />
						<span className='pink'>But Not with RED!</span>
					</h2>
					<p className='brief my-3'>
						Do you think dieting and burning more calories is
						helping you?It may help for a short while but will make
						you gain back all the weight you have lost soon.
					</p>
				</div>
				<div className='col-8 d-none d-sm-block mt-3 mb-2'>
					<div className="OfferNeverBefore joinAd flex-column">
						<div >
							Never Before Offer
						</div>
					</div>
				</div>
				<div className='col-8 d-none d-sm-block '>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};
	const ad3 = () => {
		return (
			<>
				<div className='w-100'>
					<h2 className='title'>
						<span className='pink'>Burn</span> Calories, Get{" "}
						<span className='pink'>Stronger</span>{" "}
						<br className='d-none d-sm-block' /> And{" "}
						<span className='pink'>Energise</span> Your System at
						<br className='d-none d-sm-block' /> The Same Time
					</h2>
					{/* <p className='brief my-3'>
						Do you think dieting and burning more calories is
						helping you?It may help for a short while but will make
						you gain back all the weight you have lost soon.
					</p> */}
				</div>
				<div className='col-8 d-none d-sm-block mt-3 mb-2'>
					<div className="OfferNeverBefore joinAd flex-column">
						<div >
							Never Before Offer
						</div>
					</div>
				</div>

				<div className='col-8 d-none d-sm-block '>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};
	return (
		<div className='advertisementWrapper px-3'>
			<div className='Advertisement p-sm-5 p-sm-2 p-4'>
				<div className='row row-cols-1 row-cols-sm-2 g-3'>
					<div className='col-12 col-sm-7 d-flex align-items-center'>
						<div className='w-100 h-100 d-flex flex-column justify-content-center'>
							{type == 1 && ad1()}
							{type == 2 && ad2()}
							{type == 3 && ad3()}
						</div>
					</div>
					<div className=' col-12 col-sm-5 d-flex flex-column align-items-center justify-content-center'>
						<div className='w-100'>
							<div className='d-flex align-items-center justify-content-center'>
								<img
									src='assets/moneyBackWeb.png'
									alt=''
									className='img-fluid d-none d-sm-inline-block' />

								<img
									src='assets/moneyBackMobile.png'
									alt=''
									width= "132px"
								height=" 101px"
								className='img-fluid  d-sm-none d-inline-block'
								/>
							</div>
							<div className='d-none d-sm-block'>
								<div className='remain d-flex align-items-center justify-content-center py-4'>
									Remaining Until								</div>
								<Countdown
									zeroPadtimeCountdown={2}
									date={Date.now() + diff()}
									renderer={renderer}
								/>
							</div>
						</div>
						<div className="OfferNeverBefore d-md-none d-sm-block joinAd flex-column">
							<div >
								Never Before Offer
							</div>
						</div>
						<div className=' d-sm-none d-block  my-sm-4'>
							<JoinAdd modalHandler={modalHandler} />
						</div>

						<div className='d-sm-none d-block'>
							<div className='remain d-flex align-items-center justify-content-center py-4'>
								Remaining Until
							</div>
							<Countdown
								zeroPadtimeCountdown={2}
								date={Date.now() + diff()}
								renderer={renderer}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
