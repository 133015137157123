import React from "react";
import ReactPlayer from "react-player";
import { v4 as uuidv4 } from "uuid";
import ReactPlayerComp from "../ReactPlayerComp/ReactPlayerComp";
import "./AboutBharatThakur.css";
export default function AboutBharatThakur() {
	return (
		<div className='aboutBharatThakur px-3'>
			<div className='title'>What is Yoga?</div>
			<div className='title'>
           by
Dr. Bharat Thakur</div>
			<div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>
				<div className='bannerVideo col aboutVharatThakur'>
	
					<ReactPlayerComp
						url={
							"https://artisticyoga.com/uploads/videos/red/testimonial/BT-AY-VIDEO.mp4?ngsw-bypass=true"
						}
						thumb='assets/thumbnail/Thumb/BTAYVideo.jpg'
						uid={uuidv4()}
					/>
				</div>
				<div className='description col d-flex'>
					Taken to the Himalayas at the young age of four, and having
					learnt yoga and meditation from his Master for fourteen
					years, Dr. Bharat Thakur received his post-doctoral degree
					in exercise physiology from the prestigious LNIPE Institute
					in Gwalior, India. In 1999, he introduced to the world -
					Artistic Yoga, a beautiful system of yoga that combined the
					most modern principles of sports medicine with ancient yogic
					practices. With a focus on weight loss and fitness, he
					redefined the yogic experience so that millions of people
					who found the gym boring and tiresome had another way to
					reach high levels of fitness and muscle tone while also
					practicing powerful yogic techniques for de-stressing and
					meditation.
				</div>
			</div>
		</div>
	);
}
