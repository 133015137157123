import React from "react";
import "./WhyRed.css";
export default function WhyRed() {
	return (
		<div id='whyred' className='whyred px-3'>
			<div  className='d-flex  align-items-center justify-content-center whyRedTittle'>
				<span className='whyredHeader'>Why RED?</span>
			</div>
			<div className='position-relative whyredImage'>
				<img
					src='assets/WhyRED.png'
					alt=''
					className='img-fluid d-none d-sm-block'
					style={{width:"100%",height:"auto"}}
				/>
				<img
					src='assets/whyRed3.svg'
					alt=''
					className='img-fluid d-sm-none d-block'
					style={{width:"100%",height:"auto"}}
				/>
				{/* <div className='position-absolute meditaionImage'>
					<img
						src='assets/whyREDImage.svg'
						alt=''
						className='img-fluid d-none d-sm-block'
					/>
					<img
						src='assets/whyMobileImg.svg'
						alt=''
						className='img-fluid d-block d-sm-none'
					/>
				</div>
				<div className='evenlyItem'>
					<div className='whyBox '>
						<div className='d-flex align-items-center '>
							<span>
								<img
									src='assets/deepSleep.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='whyTitle'>
								Deep and restful sleep.
							</span>
						</div>
						<div className='d-flex align-items-center left '>
							<div className='line'></div>
							<div className='solidCircle'></div>
						</div>
						<div className='whyDescription'>
							Stretching, breathing and meditation allow your body
							and mind to completely rest at night.
						</div>
					</div>
					<div className='whyBox'>
						<div className='d-flex align-items-center '>
							<span>
								<img
									src='assets/deepSleep.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='whyTitle'>
								Fixes long term health issues.
							</span>
						</div>
						<div className='d-flex align-items-center right '>
							<div className='solidCircle'></div>
							<div className='line'></div>
						</div>
						<div className='whyDescription'>
							Works on stress-related issues such as high BP,
							digestive issues, diabetes, back pain, lethargy and
							low energy.
						</div>
					</div>
				</div>
				<div className='betweenItem '>
					<div className='whyBox '>
						<div className='d-flex align-items-center '>
							<span>
								<img
									src='assets/deepSleep.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='whyTitle'>
								More energizing than tiring
							</span>
						</div>
						<div className='d-flex align-items-center left '>
							<div className='line'></div>
							<div className='solidCircle'></div>
						</div>
						<div className='whyDescription'>
							Recharges you from the inside out, instead of simply
							burning calories and tiring you out
						</div>
					</div>
					<div className='whyBox'>
						<div className='d-flex align-items-center '>
							<span>
								<img
									src='assets/deepSleep.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='whyTitle'>
								Happy and balanced hormones
							</span>
						</div>
						<div className='d-flex align-items-center right '>
							<div className='solidCircle'></div>
							<div className='line'></div>
						</div>
						<div className='whyDescription'>
							Neuromascular locks and specific postures induce the
							release of endorphins for that special yoga high!
						</div>
					</div>
				</div>
				<div className='betweenItem '>
					<div className='whyBox '>
						<div className='d-flex align-items-center '>
							<span>
								<img
									src='assets/deepSleep.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='whyTitle'>
								Better brain health
							</span>
						</div>
						<div className='d-flex align-items-center left '>
							<div className='line'></div>
							<div className='solidCircle'></div>
						</div>
						<div className='whyDescription'>
							Asanas and pranayama improve blood flow of the
							brain,while improving your focus and concentration!
						</div>
					</div>
					<div className='whyBox'>
						<div className='d-flex align-items-center '>
							<span>
								<img
									src='assets/deepSleep.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='whyTitle'>Healthy digestion.</span>
						</div>
						<div className='d-flex align-items-center right '>
							<div className='solidCircle'></div>
							<div className='line'></div>
						</div>
						<div className='whyDescription'>
							Cleansing techniques and postures that specifically
							target your entire digestive tract for optimum
							functioning.
						</div>
					</div>
				</div>
				<div className='evenlyItem'>
					<div className='whyBox '>
						<div className='d-flex align-items-center '>
							<span>
								<img
									src='assets/deepSleep.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='whyTitle'>Heart health.</span>
						</div>
						<div className='d-flex align-items-center left '>
							<div className='line'></div>
							<div className='solidCircle'></div>
						</div>
						<div className='whyDescription'>
							A structured program that steadily improves
							cardiovascular endurance.
						</div>
					</div>
					<div className='whyBox'>
						<div className='d-flex align-items-center '>
							<span>
								<img
									src='assets/deepSleep.svg'
									alt=''
									className='img-fluid'
								/>
							</span>
							<span className='whyTitle'>
								Faster and higher metabolism.
							</span>
						</div>
						<div className='d-flex align-items-center right '>
							<div className='solidCircle'></div>
							<div className='line'></div>
						</div>
						<div className='whyDescription'>
							Extended afterburn via deep muscle activation and
							toning so that your resting calorie burn rate is
							higher.
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
}
